<template>
  <div
    class="activities--container"
    ref="activitiesContainer"
    :class="{ 'activity-card--mobile': !isFullWidth }"
  >
    <div class="card-info" v-for="activityData in Tours" :key="activityData.id">
      <a
        :href="getPageUrl(activityData.id, activityData.title)"
        @click="goToDetailsPage(activityData.id)"
      >
        <div class="image--container" :style="`backgroundImage:url(${activityData.coverImage})`">
         
          <div
            class="is-flex is-align-items-center activity--rating"
            v-if="activityData.reviews && activityData.reviews.length"
          >
            <b-icon icon="star" class="mr-1 star-icon"></b-icon
            ><b>{{ getOverallRatingNumber(activityData.reviews) }}</b>
          </div>

          <div class="card--content">
            <div class="is-flex mb-4">
              <div
                class="is-flex is-align-items-center activity--duration mr-2"
                v-if="activityData.city"
              >
                <img src="../../assets/location-icon-white.svg" class="mr-1 icon" />
                <span class="duration">{{ activityData.city }}</span>
              </div>
              <div
                class="is-flex is-align-items-center activity--duration"
                v-if="activityData.duration"
              >
                <img src="../../assets/timer-icon-white.svg" class="mr-1 icon" />
                <span class="duration">{{
                  translateDuration(activityData.duration)
                }}</span>
              </div>
              
            </div>
  
            <p class="title mb-3">{{ activityData.title }}</p>
            <div class="info-section mt-2 mb-3">
              <div class="price" v-if="activityData.price">
                <!-- <img class="mr-1" src="../../assets/ticket-outline.svg" /> -->{{
                  activityData.privatePrice
                }}€
              </div>
              <div class="btn-primary">
                <!--  <img src="../../assets/location-icon.svg" class="mr-1 icon--sm"/> -->
                Learn more
              </div>
            </div>
          </div>
        </div>

        
      </a>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { ActivitiesMutations } from "../../store/Storetypes";
import { getHoursAndMinutes } from "../../helpers";
import { HelpersMixin } from "../../mixins";

export default {
  props: {
    Tours: {
      type: Array,
      default: () => [],
    },
    isFullWidth: {
      type: Boolean,
      default: true,
    },
    isMobileViewOnly: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [HelpersMixin],
  data() {
    return {
      getHoursAndMinutes,
    };
  },
  methods: {
    ...mapMutations({
      setSelectedActivityId: ActivitiesMutations.SET_SELECTED_ACTIVITY_ID,
    }),
    scrollLeft() {
      this.$refs.activitiesContainer.scrollBy({
        left: -360,
        behavior: "smooth",
      });
    },
    scrollRight() {
      this.$refs.activitiesContainer.scrollBy({
        left: 360,
        behavior: "smooth",
      });
    },
    getOverallRatingNumber(ratings) {
      if (!ratings.length) return;
      const ratingsArray = ratings.map((obj) => obj.stars);
      return this.getOverallRating(ratingsArray);
    },
    tourRating(stars, evaluations) {
      // TODO: Add translations and make text be length conditional
      return evaluations.length && stars
        ? `${stars} | ${evaluations.length} review(s)`
        : "0 reviews";
    },
    goToDetailsPage(activityId) {
      this.setSelectedActivityId(activityId);
      // this.$router.push({ name: 'details', params: { id: activityId, title: this.slugify(title) } })
    },
    getPageUrl(activityId, title) {
      return `https://www.guiders.pt/details/${activityId}/${this.slugify(
        title
      )}`;
    },
    translateDuration(duration) {
      return getHoursAndMinutes(duration);
    },
  },
};
</script>

<style lang="scss" scoped>

.info-section{
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid white;
    padding-top: 1rem;
    display: flex;
}

.image--container:after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 8px;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(14 61 77 / 80%))
}

.btn-primary {
    background-color: #67694952;
    border-radius: 20px 20px 20px 0;
    padding: 5px 18px;
    font-size: 0.9rem;
    backdrop-filter: blur(1px);
    color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.star-icon {
  color: #0e3d4d;
}

.columns {
  margin: 0;
}

.column {
  padding: 0;
}

.seperator {
  position: relative;
}

.seperator:after {
  content: "";
  position: absolute;
  margin-right: 8px;
  border-radius: 19px;
  right: 0px;
  top: 26%;
  height: 66%;
  border-right: 2px solid #0e3d4d12;
}

@media (min-width: 769px) {
  .column.is-3 {
    flex: auto;
  }
}

.image--container {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 360px;
    border-radius: 9px;
}

.exclusive--badge {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #0e3d4d;
  font-weight: 600;
  .icon {
    height: 2.2rem;
    width: 2.2rem;
    object-fit: contain;
  }
}

.activity--duration {
  color: #fff;
  border-radius: 4px;
  border: none;
  background-color: #0e3d4d0d;
  backdrop-filter: blur(6px);
  padding: 4px 12px;
  font-weight: 400;
  width: -moz-max-content;
  width: max-content;
  .duration {
    font-size: 0.75rem;
  }
  .icon {
    height: 0.9rem;
    width: 0.9rem;
    object-fit: fill;
  }
}

.activity--rating {
  background-color: #fff;
  color: #0e3d4d;
  backdrop-filter: blur(4px);
  border-radius: 9px;
  padding: 4px 12px 4px 8px;
  font-weight: 500;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 0.9rem;
}

.activity-details {
  font-weight: bold;
  color: #446df6;
}

.card {
  .card-content {
    margin: 1.5rem 0 0.95rem 0;
  }
}

.card--content {
    color: #fff !important;
    background-color: transparent !important;
    box-shadow: none;
    bottom: 0;
    position: absolute;
    z-index: 10;
    padding: 1rem 1rem 0;
    width: -webkit-fill-available;
    border-radius: 0 0 22px 22px;
    margin-top: -7px;
}

.is-flex {
  align-items: center;
}

.rating {
  font-size: 0.8rem;
}

.title {
  font-weight: 500;
  color: white !important;
}


.market-price {
  font-size: 0.9rem;
}

.title {
  margin-bottom: 0.4rem;
  font-size: 1.1rem;
  margin-top: 0.25rem;
}

.price {
  font-size: 1.05rem;
  border-radius: 8px;
  padding: 2px 0;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.columns {
  margin-bottom: 0;
  align-items: flex-start;
  .column {
    padding: 0;
    height: auto;
  }
}

.row {
  display: inline-flex;
  align-items: center;
  color: #0e3d4dbf;
  margin-bottom: 4px;
  img {
    margin-right: 8px;
  }
}

.tag:not(body) {
  margin: 16px 0px;
}

.card--image {
  height: 200px;
  border-radius: 16px 16px 0 0;
  width: -webkit-fill-available;
  object-fit: cover;
}

.activity-card--mobile {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin: 0 -20px;
  padding: 0 20px;
  &.activities--container {
    margin-right: -20px !important;
  }
  .card-info {
    width: 70vw;
    flex: 0 0 auto;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.tag:not(body) {
  margin: 8px 0px;
}

::v-deep {
    .card--content .title {
        color: white !important;
    }
    
  .featured-tag {
    position: absolute;
    top: 0;
    left: 16px;
    margin: 4px;
  }
  .card {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 8px;
  }
  .card-content {
    background-color: transparent;
    padding: 0px;
  }
}
</style>
