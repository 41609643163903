<template>
    <section>
      <div class="card-content"  ref="grandChild"
        :class="{ 'activities--container-full': isFullWidth, 'experiences--container': !isFullWidth }">
        <div v-for="(experience, index) in ExperiencesArray" :key="index"  
        :data-aos="withAnimation ? 'fade-up' : null"
    :data-aos-easing="withAnimation ? 'ease-in-out' : null"
    :data-aos-mirror="withAnimation ? 'true' : null"
    :data-aos-once="withAnimation ? 'false' : null">
          <ActivityCard v-if="!experience?.ticketLink" is-full-width :Tours="[experience]" class="experiences-card" />
           <ExternalActivityCard v-else is-full-width :Tickets="[experience]" class="experiences-card" />
        </div>
        
      </div>

      <div class="has-text-centered">
        <b-button type="is-ghost" class="mt-6" icon-right="chevron-down" v-if="experiencesCount <= ExperiencesList.length && showMore" 
        @click="showMoreExperiences">See more experiences</b-button>
      </div>
    
    </section>
  </template>
  
  <script>
  import ActivityCard from './elements/ActivityCard.vue';
  import ExternalActivityCard from './elements/ExternalActivityCard.vue';
  import { mapState, mapActions } from 'vuex';
  import { TicketsStates, ActivitiesStates, ActivitiesActions } from '../store/Storetypes';
  import { HelpersMixin } from '../mixins';
  export default {
    mixins: [HelpersMixin],
    data() {
      return {
        experiencesCount: 0
      }
    }, 
    props: {
      Experiences: {
        type: Array,
        default: () => []
      },
      Tickets: {
        type: Array,
        default: () => []
      },
      isFullWidth: {
        type: Boolean,
        default: true
      },
      isExperiencesIds: {
        type: Boolean,
        default: true
      },
      showMore: {
        type: Boolean,
        default: true
      },
      withAnimation: {
      type: Boolean,
      default: true
    }
    },
    components: {
      ActivityCard,
      ExternalActivityCard
    },
    mounted(){
      this.experiencesCount = this.counter;
      this.getActivities()
    },
    methods: {
      ...mapActions({
      getActivities: ActivitiesActions.GET_ACTIVITIES
    }),
      scrollLeft() {
      this.$refs.grandChild.scrollBy({ left: -360, behavior: 'smooth' });
    },
    scrollRight() {
      this.$refs.grandChild.scrollBy({ left: 360, behavior: 'smooth' });
    },
      getExperienceById(experiences, type = "tours") {
        return this[type].filter((element) => experiences.includes(element.id))
      },
      getExperiences(activitiesIds) {
        const activities = this.getExperienceById(activitiesIds)
       // const tickets = this.getExperienceById(ticketsIds, 'tickets')

        const objectsMap = activities.reduce((map, obj) => {
            map[obj.id] = obj;
            return map;
        }, {});

        // Return the objects array sorted according to idsArray
        return this.Experiences.map(id => objectsMap[id])
      //  return [...activities, ...tickets]
      },
      showMoreExperiences() {
        this.experiencesCount += this.counter
        this.ExperiencesArray = this.ExperiencesList.slice(0, this.experiencesCount)
      },
      reset(){
        this.experiencesCount = this.counter
        this.getExperiences(this.Experiences, this.Tickets).slice(0, this.experiencesCount)
      }
    },
    computed: {
      ...mapState({
        tours: state => state.activities[ActivitiesStates.ACTIVITIES],
        tickets: state => state.tickets[TicketsStates.TICKETS]
      }),
      counter(){
        return this.isMobileView ? 10 : 15
      },
      ExperiencesList() {
        if (!this.isExperiencesIds) {
          return [...this.Experiences, ...this.Tickets]
        }
        return this.getExperiences(this.Experiences, this.Tickets).slice(0, this.experiencesCount);
  
      },
      ExperiencesArray: {
        get() {
         return this.ExperiencesList.slice(0, this.experiencesCount);
        },
        set() {
          this.experiencesCount += 15;
        }
  
      }
    }
  
  
  }
  </script>
  
  <style lang="scss" scoped>
  .button {
    font-size: 1rem;
    height: 2.5rem;
    font-weight: 700;
    color: #0e3d4d;
  }
  
  @media (max-width: 769px) {
    .activities--container-full {
      grid-template-columns: repeat(1, minmax(min-content, 1fr)) !important;
    }
  }
  
  .activities--container-full {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, minmax(min-content, 1fr));
  
    .experiences-card {
      width: auto;
    }
  
    &.card-content {
      padding: 0 !important;
    }
  }
  
  .experiences--container {
    display: flex;
    gap: 1rem;
    overflow: auto;
  }
  
  .experiences-card {
    width: 78vw;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  @media only screen and (min-width: 40em) {
    .experiences-card {
      width: 54vw;
    }
  }
  
  /* Medium devices such as tablets (768px and up) */
  @media only screen and (min-width: 48em) {
    .experiences-card {
      width: 40vw;
    }
  }
  
  /* Large devices such as laptops (1024px and up) */
  @media only screen and (min-width: 64em) {
    .experiences-card {
      width: 31vw;
    }
  }
  
  /* Largest devices such as desktops (1280px and up) */
  @media only screen and (min-width: 80em) {
    .experiences-card {
      width: 21vw;
    }
  }
  
  @media only screen and (min-width: 1700px) {
    .experiences-card {
      width: 18vw;
    }
  }
  
  
  @media only screen and (min-width: 2100px) {
    .experiences-card {
      width: 16vw;
    }
  }
  
  
  @media only screen and (min-width: 2350px) {
    .experiences-card {
      width: 14vw;
    }
  }
  
  .experience-card--mobile {
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin: 0 -20px;
    padding: 0 20px;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  .experiences--container {
    margin-right: -20px;
    margin-left: -20px;
    padding-left: 20px !important;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  .card-content {
    padding: 0 20px 16px 0;
  }
  </style>